<template>
  <div>
    <div class="result">
      QR: {{qrdata}}
      <hr>
      Receipt: {{receiptdata}}
    </div>
    <pre class="debug">
    {{debug}}
    </pre>
    <div class="center stream">
      <qr-stream @decode="onDecode" class="mb">
        <div style="color: red;" class="frame"></div>
      </qr-stream>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import { QrStream } from 'vue3-qr-reader'
import axios from 'axios'

export default defineComponent({
  name: 'QrStreamExample',
  components: {
    QrStream
  },
  setup() {
    const state = reactive({
      qrdata: null,
      receiptdata: null,
      debug: '',
      params: '',
      json: ''
    })
    function debug(s) {
      state.debug += "\r\n"
      state.debug += s
    }
    function onDecode(qrdata) {
      state.qrdata = qrdata
      let json = {
        "hash": window.location.pathname.substring(1),
        "qr": qrdata
      }
      const config = {
          baseURL: process.env.VUE_APP_BACKEND,
          timeout: 50000,
          headers: {
              'Content-type': 'application/json',
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*'
          }
        }
      console.log(config)
      axios.post('qr', json, config)
        .then(() => {
          // state.receiptdata = JSON.stringify(response.data)
          state.receiptdata = 'added to queue'
          debug('==> qr-code result: added to queue')
          // console.log(response)
          // debug('post done')
        })
        .catch((error) => {
          //state.receiptdata = JSON.stringify(json)
          debug('==> qr-code error:')
          debug(error)
        })
    }
    return {
      ...toRefs(state),
      onDecode
    }
  }
});
</script>

<style scoped>
.stream {
  max-height: 500px;
  max-width: 500px;
  margin: auto;
}
.frame {
  border-style: solid;
  border-width: 2px;
  border-color: red;
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: auto;
}
.debug {
  overflow: scroll;
  padding: 3px;
  font: 'Courier New', monotype;
  background: black;
  color: lightgreen;
  text-align: left;
}
</style>